<!-- 
	This is the sign in page, it uses the dashboard layout in: 
	"./layouts/Default.vue" .
 -->

 <template>
	<div class="sign-in bg-gray-3">
		
		<a-row type="flex" :gutter="[24,24]" justify="space-around" align="middle" style="padding-top: 30px; margin-top: 30px">

			<a-col :span="24" :xs="24" :sm="24" :md="24" :lg="24" :xl="22" class="col-form">
				
				<a-card :bordered="false" class="card-info px-5 mx-0 pt-20">
					<a-row :gutter="[24,24]">
						<a-col :span="24">
							<a-row class="mt-0 pt-2 mb-20">

								<!-- <a-col :span="12" class="text-left pl-10">
									<a-button id="btnBordered" type="text" class="primary" v-if="(current > 0 && current <= 2) || current > 3" @click="previousScreen"><a-icon type="arrow-left" theme="outlined" /> &nbsp;&nbsp;  Back </a-button>
								</a-col> -->

								<!-- <a-col :span="12" class="text-right pr-10">
									<a-button id="btnBordered" type="text" class="primary" @click="skipScreen"> Skip <a-icon type="arrow-right" theme="outlined" /> &nbsp;&nbsp; </a-button>
								</a-col> -->
							</a-row>
							<!-- <a-steps :current="current">
								<a-step v-for="item in steps" :key="item.title" :title="item.title" />
							</a-steps> -->
							<a-row class="mt-0 pt-2">
								<a-col :span="24" class="px-10">
									<a-progress strokeColor="#734EBF" trailColor="success" :percent="Math.floor(((current + 1) / 2) * 100)" />
								</a-col>
							</a-row>
							<div class="steps-content">
								<!-- {{ steps[current].content }} -->
								<template>
									<a-row ty :gutter="[24,24]">
										
										<a-col :span="24" :md="24" :lg="24">

											<a-row type="flex" :gutter="24" justify="space-around" align="top" style="margin-top: 50px;" v-if="current==0">
												<a-col :span="24" :md="12" :lg="12" :xl="{span: 12, offset: 0}" class="col-form pt-2">

													<a-row ty :gutter="24" class="text-center">
														<a-col :span="24">
															<label class="text-center mt-0 pt-0" style="font-size: 18px;">
																<strong>Enter your institution ID or admission number</strong>
															</label>
														</a-col>
													</a-row>

													<a-alert
														class="mt-10"
														v-if="errorInstitutionId.show"
														:message="errorInstitutionId.message"
														type="error"
														closable
														:after-close="handleClose"
														show-icon 
													/>

													<a-form
														id="components-form-demo-normal-login"
														:form="institutionIDForm"
														class="login-form mt-20 pt-20"
														@submit="handleSubmitInstitutionID"
														:hideRequiredMark="true">

														<a-form-item class="mb-10" label="Institution ID" :colon="false">
															<a-input 
																type="number"
																v-decorator="[ 'code', { rules: [{ required: true, message: 'Please enter your institution ID!' }] }, ]" 
																placeholder="Enter your institution ID" />
														</a-form-item>
														
														<a-form-item class="mt-2 text-center mt-20 pt-5">
															<a-button :loading="institutionIdLoading" type="primary" style="width: 100px !important;" block html-type="submit" class="login-form-button mt-10 text-white" >
																Verify
															</a-button>
														</a-form-item>
														
													</a-form>
												</a-col>
											</a-row> 

											<a-row type="flex" :gutter="24" justify="space-around" align="top" style="margin-top: 50px;" v-if="current==1">
												<a-col :span="24" :md="12" :lg="12" :xl="{span: 12, offset: 0}" class="col-form pt-2">

													<a-row ty :gutter="24" class="text-center">
														<a-col :span="24">
															<label class="text-center mt-0 pt-0" style="font-size: 18px;">
																<strong>Enter verification token sent to your contact</strong>
															</label>
														</a-col>
													</a-row>

													<a-alert
														class="mt-10"
														v-if="errorToken.show"
														:message="errorToken.message"
														type="error"
														closable
														:after-close="handleClose"
														show-icon 
													/>

													<a-form
														id="components-form-demo-normal-login"
														:form="tokenForm"
														class="login-form mt-20 pt-20"
														@submit="handleSubmitToken"
														:hideRequiredMark="true">

														<a-form-item class="mb-10" label="Token" :colon="false">
															<a-input 
																type="number"
																v-decorator="[ 'token',
																{ rules: [{ required: true, message: 'Please input verification code!' }] },
																]" 
																placeholder="Enter verification code" />
														</a-form-item>

														<a-row>
															<a-col :span="24" class="text-center pb-5">
																Didn't receive token? <a-button id="textBtn" class="text-primary px-0 mx-0 pl-2" type="text" @click="handleResendToken" style="text-decoration: none !important; font-size: 14px;">
																	Resend token
																</a-button>
															</a-col>
														</a-row>
														
														<a-form-item class="mt-2 text-center mt-20 pt-5">
															<a-button :loading="tokenLoading" type="primary" style="width: 100px !important;" block html-type="submit" class="login-form-button mt-10 text-white" >
																Verify
															</a-button>
														</a-form-item>
														
													</a-form>
												</a-col>
											</a-row> 
		
										</a-col>
									</a-row>
								</template>
							</div>
							<div class="steps-action">
								<!-- <a-button v-if="current < steps.length - 1" type="primary" @click="next">Next</a-button>
								
								<a-button v-if="current > 0" style="margin-left: 8px" @click="prev">Previous</a-button> -->
							</div>
						</a-col>
					</a-row>

				</a-card>
			</a-col>


		</a-row>
		
	</div>
</template>

<script>
    import { notification } from 'ant-design-vue';
    import VueCountryCode from "vue-country-code-select";

	export default ({
		components: {
			VueCountryCode
		},

		data() {
			return {
	
				current: 0,
				steps: [
					{
						title: 'Institution Id',
						content: 'Third-content',
					},
					{
						title: 'Verification Token',
						content: 'Fourth-content',
					},
				],

				user: {},

				validCode: null,

				institutionIdLoading: false, 

				errorInstitutionId: {
					message: null,
					show: false,
				},

				tokenLoading: false,

				errorToken: {
					message: null,
					show: false,
				},
				
			}
		},
		async mounted () {

			let breadcrumbs = [
				{ title: 'Institutions', url: `/institutions-list`, isActive: false },
				{ title: 'Join', url: `/institutions-list`, isActive: true },
			];

			await this.$root.$refs.Breadcrumbs.initializeBreadCrumbs(breadcrumbs)
	
		},
		beforeCreate() {
			this.institutionIDForm = this.$form.createForm(this, { name: 'institution_id_form' });
			this.tokenForm = this.$form.createForm(this, { name: 'token_form' });
		},
		created() {
		},
		methods: {

			nextScreen() {
				this.current++;
			},

			previousScreen() {
				if(this.current > 0 && this.current <= 2) {
					this.current--;
				}

				if(this.current > 3) {
					this.current--;
				}
			},

			skipScreen() {
				this.skipConfirmation.showModal = true;
			},

			cancelModal() {
				this.skipConfirmation.showModal = false;
			},

			toHomePage() {
				this.$router.push("/home")
			},


			handleLearningSelection(isAcademic) {
				this.learning.isAcademic = isAcademic;
			},

			handleClose() {
				this.error.show = false;
			},


			handleSubmitInstitutionID(e) {
				e.preventDefault();

				this.institutionIDForm.validateFields((err, values) => {
					if ( !err ) {

						this.errorInstitutionId.show = false;
						this.errorInstitutionId.message = null;

						this.institutionIdLoading = true;

						const code = values.code;

						let url = `${this.$BACKEND_URL}/institutions/users/verify-member-id`;

						this.$AXIOS.post(url, { code }).then(async(response) => {
							if (response.status >= 200 && response.status < 210) {

								this.validCode = code;

								this.notify(`Verification token has been sent to your contact`, 'success')

								this.nextScreen();
								
							}
							this.institutionIdLoading = false;
						}).catch((err) => {
							console.log(err)
							this.institutionIdLoading = false;
							
							this.errorInstitutionId.message = err.response != null  && err.response.data != null ? err.response.data.message : "Connection error";
							this.errorInstitutionId.show = true
						});

					}
				});
			},


			handleResendToken() {
				

				let url = `${this.$BACKEND_URL}/institutions/users/verify-member-id`;

				this.$AXIOS.post(url, { code: this.validCode }).then(async(response) => {
					if (response.status >= 200 && response.status < 210) {

						this.notify(`Verification token has been sent to your contact`, 'success')
						
					}
					this.institutionIdLoading = false;
				}).catch((err) => {
					console.log(err)
					this.institutionIdLoading = false;
					
					this.errorInstitutionId.message = err.response != null  && err.response.data != null ? err.response.data.message : "Connection error";
					this.errorInstitutionId.show = true
				});
			},


			handleSubmitToken(e) {
				e.preventDefault();

				this.tokenForm.validateFields((err, values) => {
					if ( !err ) {

						this.errorToken.show = false;
						this.errorToken.message = null;

						this.tokenLoading = true;

						console.log(values)
						const token = values.token;

						let url = `${this.$BACKEND_URL}/institutions/users/verify-member-token`;

						this.$AXIOS.post(url, { token }).then(async(response) => {
							if (response.status >= 200 && response.status < 210) {

								if(response.data.data != null && response.data.data.hasJoined) {
									let data = response.data.data;

									await this.joinInstitution(data.clazz != null);
									if(data.clazz != null) {
										await this.joinClass(data.clazz);
									}else{

									}
								}
								
							}
							this.tokenLoading = false;
						}).catch((err) => {
							console.log(err)
							this.tokenLoading = false;
							
							this.errorToken.message = err.response != null  && err.response.data != null ? err.response.data.message == 'User not found' ? 'Invalid token' : err.response.data.message : "Connection error";
							this.errorToken.show = true
						});

					}
				});
			},


			async joinInstitution(hasClass) {
				const userInfo = await localStorage.getItem('user_details')

				let userDetails = JSON.parse(userInfo);

				this.joinInstitutionLoading = true;

				let url = `${this.$BACKEND_URL}/institutions/${this.$route.params.uuid}/member`;

				this.$AXIOS.post(url, {uuid: userDetails.uuid, isTutor: false, isStudent: true, isAdmin: false}).then(async(response) => {

                    this.notify('You have successfully joined the institution', 'success')

					if(!hasClass) {
						this.$router.push(`/institutions-list/view/${this.$route.params.uuid}`)
					}

                    this.joinInstitutionLoading = false;

                }).catch(async(error) => {
					
					this.joinInstitutionLoading = false;

					if(error.response && error.response.status == 401) {
						await localStorage.setItem("user_token", null);
						await localStorage.setItem("user_details", null)
						await localStorage.setItem("institution_details", null)

						this.$router.push(`/sign-in`);
					}
				});
			},


			async joinClass(classUuid) {

				const userInfo = await localStorage.getItem('user_details')

				let userDetails = JSON.parse(userInfo);

				this.joinClassLoading = true;

				let url = `${this.$BACKEND_URL}/members/${classUuid}`;

				this.$AXIOS.post(url, {uuid: userDetails.uuid}).then(async(response) => {

					this.notify('You have successfully joined the class', 'success')

					this.joinClassLoading = false;

					this.$router.push(`/my-classes/view/${classUuid}`)

				}).catch(async(error) => {
					
					this.joinClassLoading = false;

					if(error.response && error.response.status == 401) {
						await localStorage.setItem("user_token", null);
						await localStorage.setItem("user_details", null)
						await localStorage.setItem("institution_details", null)

						this.$router.push(`/sign-in`);
					}
				});
			},

			notify(message, type) {
                notification[type]({
                    message: message,
                    description: '',
                    placement: 'topRight',
                    duration: 3,
                });
                
            },

		},
	})

</script>

<style lang="scss" scoped>
	
</style>